<template>
  <div class="px-5 text-center">
    <div class="mb-10">
      <IcoContractRejected type="error" />
    </div>

    <v-card class="mx-auto mb-10" :elevation="$vuetify.breakpoint.mobile ? 0 : 2" max-width="45rem">
      <v-card-title>
        <div class="static__layout--title display-1 mx-auto mt-5">
          404
        </div>
      </v-card-title>

      <v-card-text>
        <p class="subtitle-1 my-5">
          {{ $t('general.not_found') }}
        </p>
      </v-card-text>

      <v-card-actions class="pa-5 pt-0">
        <v-btn
          color="primary"
          class="mx-auto white--text"
          :min-width="$vuetify.breakpoint.mobile ? '15rem' : '25%'"
          :x-large="$vuetify.breakpoint.mobile"
          @click="$router.push({ name: 'home' })"
        >
          {{ $t('contract.back_to_dashboard') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import IcoContractRejected from '@/components/svg/IcoContractRejected.vue'

export default {
  name: 'NotFound',
  components: {
    IcoContractRejected,
  },
};
</script>

<style lang="scss" scoped>
.static__layout--title {
  word-break: break-word;
}
</style>
